import { LoadingSpinner } from '@motion/ui/base'

export const FullScreenLoading = () => {
  return (
    <div className='static-loading relative'>
      <div className='absolute left-0 top-0 w-full h-8 electron-drag' />
      <h2>
        <LoadingSpinner />
        <span>Preparing Motion...</span>
      </h2>
    </div>
  )
}
