import { useOnceWhen } from '@motion/react-core/hooks'
import { ThemeResolver } from '@motion/theme/dom'
import { Sentry } from '@motion/web-base/sentry'
import { useHasTreatment } from '@motion/web-common/flags'
import {
  getLatestNavigationTiming,
  stats,
} from '@motion/web-common/performance'

import { LoadingPage } from '~/areas/global'
import { useEffect, useState } from 'react'

import { type PlanType } from './choose-plan-page'
import { ElementsWrapper } from './ElementsWrapper'
import { PaymentForm } from './payment-form'

import { generatePaymentIntent, getStripe } from '../../utils'
import { CREATE_PAYMENT_INTENT_UNAUTHORIZED_URL } from '../constants'

interface CheckoutPaymentPageProps {
  userEmail: string
  isTrial: boolean
  signOutEmail: () => void
  planType?: PlanType
  onBack?: () => void
}

export const CheckoutPaymentPage = ({
  userEmail,
  isTrial,
  signOutEmail,
  onBack,
  planType,
}: CheckoutPaymentPageProps) => {
  const [clientSecret, setClientSecret] = useState('')
  const [outerError, setOuterError] = useState<string | null>(null)
  const noCcB2bEnabled = useHasTreatment('no-cc-b2b-everywhere')

  useEffect(
    function preparePaymentIntent() {
      const fetchPrices = async () => {
        try {
          const secret = await generatePaymentIntent({
            url: CREATE_PAYMENT_INTENT_UNAUTHORIZED_URL,
            emailOverride: userEmail,
          })
          if (!secret) {
            throw new Error('Payment intent generate failed')
          }
          setClientSecret(secret)
        } catch (e) {
          Sentry.captureException(e, { tags: { position: 'fetchPrices' } })
          setOuterError(
            'Error: We are experiencing issues with our payment provider. You have not been charged. Please refresh the page and try again.'
          )
        }
      }

      fetchPrices()
    },
    [userEmail]
  )

  useOnceWhen(clientSecret != null, () => {
    const loginMark = stats.getLatestMark('checkout.login')
    const chosePlanMark = stats.getLatestMark('checkout.choose-plan.selected')

    // Only record metrics when the user lands on this page directly
    if (loginMark != null) return
    if (chosePlanMark != null) return null

    const nav = getLatestNavigationTiming()
    if (nav == null) return

    stats.distribution('checkout.payment', performance.now() - nav.requestStart)
  })

  if (!clientSecret) {
    return (
      <div className='fill settings-container'>
        <LoadingPage id='webapp-checkout-payment-new' />
      </div>
    )
  }

  return (
    <ThemeResolver theme='light'>
      <ElementsWrapper
        stripe={getStripe()}
        clientSecret={clientSecret}
        key={clientSecret}
      >
        <PaymentForm
          userEmail={userEmail}
          signOutEmail={signOutEmail}
          outerError={outerError}
          isTrial={isTrial}
          updateClientSecret={(secret, error) => {
            setOuterError(error)
            setClientSecret(secret)
          }}
          // The checkout flow for team and enterprise are the same
          isTeam={planType === 'Enterprise' || planType === 'Team'}
          onBack={onBack}
          noCcB2bEnabled={noCcB2bEnabled && planType === 'Team'}
        />
      </ElementsWrapper>
    </ThemeResolver>
  )
}
