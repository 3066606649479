import { UnstyledModal } from '@motion/ui/base'

import { twMerge } from 'tailwind-merge'

type MobileWebModalProps = {
  onClose: () => void
  children: React.ReactNode
  className?: string
}

export const MobileWebModal = ({
  onClose,
  children,
  className,
}: MobileWebModalProps) => {
  return (
    <UnstyledModal
      visible
      onClose={onClose}
      overlayClassName='bg-modal-overlay'
      modalClassName={twMerge(
        'w-[90%] overflow-hidden bg-semantic-neutral-bg-default px-4 py-6 border-semantic-neutral-border-default rounded-xl',
        className
      )}
    >
      {children}
    </UnstyledModal>
  )
}
