import { Sentry } from '@motion/web-base/sentry'
import { getRedirectResult } from '@motion/web-common/firebase'

import {
  type Auth,
  type AuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  type UserCredential,
} from 'firebase/auth'

import { logEvent } from '../../analytics'

export type FirebaseResponse =
  | {
      state: 'VALID'
      credential: UserCredential
    }
  | {
      state: 'ERROR'
      // TODO: Add more specific error types from firebase if possible
      error: string
    }

export async function signInWithFirebasePopup(
  firebaseAuth: Auth,
  provider: AuthProvider
): Promise<FirebaseResponse> {
  logEvent('SIGNIN_POPUP', { provider: provider.providerId })
  try {
    // In case firebase throws an error we want to check it below
    const result = await signInWithPopup(firebaseAuth, provider)
    logEvent('SIGNIN_POPUP_SUCCESS')

    return {
      state: 'VALID',
      credential: result,
    }
  } catch (e) {
    const firebaseError = e as { code: string }

    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithpopup
    switch (firebaseError.code) {
      case 'auth/user-cancelled':
      case 'auth/popup-closed-by-user':
      case 'auth/cancelled-popup-request':
        logEvent('SIGNIN_POPUP_CANCELLED')
        break
      case 'auth/popup-blocked':
        logEvent('SIGNIN_POPUP_BLOCKED')
        break
      case 'auth/operation-not-supported-in-this-environment':
        logEvent('SIGNIN_POPUP_NOT_SUPPORTED')
        break
      case 'auth/account-exists-with-different-credential':
        logEvent('SIGNIN_POPUP_ACCOUNT_EXISTS')
        break
      case 'auth/invalid-credential':
        logEvent('SIGNIN_POPUP_INVALID_CREDENTIAL')
        break
      default:
        Sentry.captureException(new Error('Firebase: Unhandled Popup error'), {
          extra: { firebaseError },
          tags: { position: 'signInWithFirebasePopup' },
        })
    }
    return {
      state: 'ERROR',
      error: firebaseError.code,
    }
  }
}

export async function signInWithFirebaseRedirect(
  firebaseAuth: Auth,
  provider: AuthProvider
): Promise<FirebaseResponse> {
  logEvent('SIGNIN_REDIRECT', { provider: provider.providerId })

  try {
    await signInWithRedirect(firebaseAuth, provider)
    const result = await getRedirectResult(firebaseAuth)
    if (!result) {
      return {
        state: 'ERROR',
        error: 'No result',
      }
    }

    logEvent('SIGNIN_REDIRECT_SUCCESS')
    return {
      state: 'VALID',
      credential: result,
    }
  } catch (e) {
    const firebaseError = e as { code: string }
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithredirect
    // The errors from the redirect are more critical to handle so we should log it
    Sentry.captureException(new Error('Firebase: Unhandled Redirect error'), {
      extra: { firebaseError },
      tags: { position: 'signInWithFirebaseRedirect' },
    })

    return {
      state: 'ERROR',
      error: firebaseError.code,
    }
  }
}

export async function createUserWithFirebaseEmailPassword(
  firebaseAuth: Auth,
  email: string,
  password: string
): Promise<FirebaseResponse> {
  try {
    const result = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    )

    logEvent('SIGNUP_EMAIL_PASSWORD_SUCCESS')
    return {
      state: 'VALID',
      credential: result,
    }
  } catch (e) {
    const firebaseError = e as { code: string }
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
    switch (firebaseError.code) {
      case 'auth/email-already-in-use':
        logEvent('SIGNUP_EMAIL_ALREADY_IN_USE')
        break
      case 'auth/invalid-email':
        logEvent('SIGNUP_INVALID_EMAIL')
        break
      case 'auth/weak-password':
        logEvent('SIGNUP_WEAK_PASSWORD')
        break
      case 'auth/network-request-failed':
        logEvent('SIGNUP_REQUEST_FAILED')
        break
      default: {
        Sentry.captureException(
          new Error('Firebase: Unhandled Email/Password error'),
          {
            extra: { firebaseError },
            tags: { position: 'createUserWithFirebaseEmailPassword' },
          }
        )
      }
    }

    return {
      state: 'ERROR',
      error: firebaseError.code,
    }
  }
}

export async function signInWithFirebaseEmailPassword(
  firebaseAuth: Auth,
  email: string,
  password: string
): Promise<FirebaseResponse> {
  logEvent('SIGNIN_EMAIL_PASSWORD', { email })

  try {
    const result = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    )

    logEvent('SIGNIN_EMAIL_PASSWORD_SUCCESS')

    return {
      state: 'VALID',
      credential: result,
    }
  } catch (e) {
    const firebaseError = e as { code: string }
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
    switch (firebaseError.code) {
      case 'auth/invalid-email':
        logEvent('SIGNIN_INVALID_EMAIL')
        break
      case 'auth/user-disabled':
        logEvent('SIGNIN_USER_DISABLED')
        break
      case 'auth/user-not-found':
        logEvent('SIGNIN_USER_NOT_FOUND')
        break
      case 'auth/wrong-password':
        logEvent('SIGNIN_WRONG_PASSWORD')
        break
      case 'auth/network-request-failed':
        logEvent('SIGNIN_REQUEST_FAILED')
        break
      default: {
        Sentry.captureException(
          new Error('Firebase: Unhandled Email/Password error'),
          {
            extra: { firebaseError },
            tags: { position: 'signInWithFirebaseEmailPassword' },
          }
        )
      }
    }

    return {
      state: 'ERROR',
      error: firebaseError.code,
    }
  }
}
