import { Button } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { isPasswordValid } from '@motion/ui-logic'
import { logEvent } from '@motion/web-base/analytics'

import logo from '~/images/logo_1024.png'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'

import { signInEmail, signUpEmail } from '../../../utils'

export const EmailPasswordSignup = ({
  email,
  initialPassword = '',
  onBack,
}: {
  email: string
  initialPassword?: string
  onBack: () => void
}) => {
  const auth = getAuth(getApp())
  const [error, setError] = useState<null | string>(null)
  const [password, setPassword] = useState(initialPassword)
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const emailPasswordSignup = async () => {
    setError(null)
    setLoading(true)

    const signupValidationErrors = []
    if (!isPasswordValid(password)) {
      signupValidationErrors.push('password')
    }
    logEvent('DASHBOARD_MOTION_SIGNUP_CLICK', { email, signupValidationErrors })

    if (signupValidationErrors.length) {
      setValidationErrors(signupValidationErrors)
      setError('Please enter a password of at least 8 characters')
      setLoading(false)
      return
    }

    const result = await signUpEmail(auth, email, password)
    if (!result.outcome) {
      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
      const firebaseError = result.error

      logEvent('DASHBOARD_MOTION_SIGNUP_ERROR', {
        email,
        error: firebaseError,
      })

      switch (firebaseError) {
        case 'auth/email-already-in-use':
          const signInResult = await signInEmail(auth, email, password)
          if (signInResult.outcome) {
            break
          }
          setError(
            'Unable to complete registration. Please check your details and try again.'
          )
          break
        case 'auth/invalid-email':
          setError('This email address in invalid. Please try a different one.')
          break
        case 'auth/weak-password':
          setError(
            'Please choose a stronger password. We recommend at least 8 characters and one numeric character.'
          )
          break
        default:
          setError(
            'Something went wrong. Please contact support@usemotion.com or visit help.usemotion.com'
          )
          break
      }
    }

    setLoading(false)
  }

  return (
    <div className='w-full h-full flex justify-center pt-[72px]'>
      <div className='w-full max-w-[600px] flex flex-col gap-y-14'>
        <img src={logo} className='h-12 w-12' alt='Motion Icon' />
        <form
          className='w-full'
          onSubmit={(e) => {
            e.preventDefault()
            void emailPasswordSignup()
          }}
        >
          <div className='w-full flex flex-col gap-y-6'>
            <p className='text-[32px] text-semantic-neutral-text-default font-bold'>
              Create your password for {email}
            </p>
            <TextField
              label='Password (required)'
              placeholder='Enter at least 8 characters'
              onChange={setPassword}
              value={password}
              type='password'
              sentiment={
                validationErrors.includes('password') ? 'error' : undefined
              }
              autoFocus
            />
            <div className='w-full flex flex-col gap-y-2'>
              {error && (
                <p className='text-sm text-semantic-error-text-default'>
                  {error}
                </p>
              )}
              <Button type='submit' loading={loading}>
                Continue
              </Button>
              <Button
                variant='muted'
                sentiment='neutral'
                onClick={onBack}
                loading={loading}
              >
                Back
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
