/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowLeftOutline,
  CheckOutline,
  ChevronLeftOutline,
  ChevronRightOutline,
  ClockSolid,
  type SvgIcon,
  XOutline,
} from '@motion/icons'
import { useClosure, useMediaQuery } from '@motion/react-core/hooks'
import {
  BaseModal,
  Button,
  DatePicker,
  LoadingSpinner,
  Tooltip,
} from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'
import { formatDurationTime } from '@motion/ui-logic'
import { byValue, Compare } from '@motion/utils/array'
import { parseDate } from '@motion/utils/dates'
import { isEmailValid } from '@motion/utils/string'
import { isMobileExperience } from '@motion/web-base/env'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import logo from '~/images/logo_1024.png'
import { DateTime } from 'luxon'
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import { useParams, useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { BookingLinkExpired } from './booking-link-expired'
import { EmailSelect } from './email-select'
import {
  formatFullDate,
  formatFullDateTimeRange,
  formatTimeRange,
} from './utils'

import { logEvent } from '../analytics'
import { getBookingUrl, getTzAbbr } from '../utils'

type IconButtonProps = React.ComponentProps<'button'> & {
  Icon: SvgIcon
}
const IconButton = (props: IconButtonProps) => {
  const { Icon, className = '', ...rest } = props
  return (
    <button
      className={`w-9 h-9 rounded-full flex items-center justify-center ${className}`}
      {...rest}
    >
      <Icon style={{ width: 18, height: 18 }} />
    </button>
  )
}

type Timeslot = {
  start: string
  end: string
  preferred: boolean
}

type TimeslotObject = {
  [key: string]: Timeslot[]
}

const getBookingLinkInfoUrl = () => getBookingUrl('booking/cockroach/link')
const getCreateBookingUrl = () => getBookingUrl('booking/cockroachCreate')
const getRescheduleUrl = (bookingId: string) =>
  getBookingUrl('booking/cockroachReschedule', bookingId)

type BookingPageProps = {
  onBookingCreated?: () => void
}

export const BookingPage = ({ onBookingCreated }: BookingPageProps = {}) => {
  const [searchParams] = useSearchParams()
  const userEmail = searchParams.get('userEmail')
  const [loading, setLoading] = useState(true)
  const [slotsLoading, setSlotsLoading] = useState(false)
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [loadError, setLoadError] = useState(false)
  const [savingError, setSavingError] = useState('')
  const [saving, setSaving] = useState(false)

  const [authorName, setAuthorName] = useState('')
  const [teamId, setTeamId] = useState('')
  const [eventName, setEventName] = useState('')
  const [inviteeEmail, setInviteeEmail] = useState(userEmail ?? '')
  const [inviteeName, setInviteeName] = useState('')
  const [initialName, setInitialName] = useState('')
  const [guestsExpanded, setGuestsExpanded] = useState(false)
  const [guestsList, setGuestsLists] = useState<string[]>([])
  const [guestsError, setGuestsError] = useState(false)
  const [reason, setReason] = useState('')
  const [groupedSlots, setGroupedSlots] = useState<any>({})
  const [days, setDays] = useState<string[]>([])
  const [months, setMonths] = useState<string[]>([])
  const [dayIndex, setDayIndex] = useState(0)
  const [calendarDateClicked, setCalendarDateClicked] = useState(false)
  const [confirmedSlot, setConfirmedSlot] = useState<{
    start?: string
    end?: string
  }>({})
  const [selectedSlot, setSelectedSlot] = useState<{
    start?: string
    end?: string
  }>({})
  const [cardPage, setCardPage] = useState('booking') // booking, confirmation, durationChoice
  const [calendarDate, setCalendarDate] = useState(DateTime.now().toISO())

  const [duration, setDuration] = useState(0)
  const [durationChoices, setDurationChoices] = useState<any[]>([])
  const [durationAvailabilitySlots, setDurationAvailabilitySlots] =
    useState<any>({})
  const [modalVisible, setModalVisible] = useState(false)

  const [tzAbbr, setTzAbbr] = useState('')
  const [bookingData, setBookingData] = useState<any | null>(null)
  const [linkRange, setLinkRange] = useState({
    start: DateTime.now().toISO(),
    end: DateTime.now().toISO(),
  })
  const [fullLinkId, setFullLinkId] = useState('')
  const [questions, setQuestions] = useState<any[]>([])
  // TODO: Once the booking migration is complete we will update the params to match the new DB schema
  const { linkSlug, bookingId, urlPrefix } = useParams<{
    linkSlug: string
    bookingId: string
    urlPrefix: string
  }>()
  const lastMonthRequested = useRef('')

  const maxOneColumn = useMediaQuery('(max-width: 768px)')
  const maxTwoColumn = useMediaQuery('(max-width: 1024px)')

  const location = useLocation()
  const isFullPage =
    location.pathname.includes('meet') ||
    location.pathname.includes('reschedule')

  const groupSlots = useCallback(
    (
      availabilityList: Timeslot[],
      existingSlots: TimeslotObject,
      newMonths: string[] = []
    ) => {
      if (!availabilityList) return
      const tempSlots: TimeslotObject = {}
      availabilityList.forEach((slot: Timeslot) => {
        const day = parseDate(slot.start).toFormat('LL/dd/yyyy')
        if (!tempSlots[day]) {
          tempSlots[day] = []
        }
        tempSlots[day].push({
          start: slot.start,
          end: slot.end,
          preferred: !!slot.preferred,
        })
      })
      Object.keys(tempSlots).forEach((day) => {
        tempSlots[day].sort(
          byValue((v) => parseDate(v.start), Compare.dateTime)
        )
      })

      const newSlots = Object.assign(tempSlots, existingSlots)
      setGroupedSlots(newSlots)
      const allDays = Object.keys(newSlots)
      allDays.sort(byValue((v) => parseDate(v), Compare.dateTime))
      setDays(allDays)
      setMonths((prev) => {
        const allMonths = new Set<string>([...newMonths, ...prev])
        allDays.forEach((day) =>
          allMonths.add(parseDate(day).toFormat('LL/yyyy'))
        )
        return Array.from(allMonths)
      })
      const params = new URLSearchParams(window.location.search)
      if (
        allDays.length &&
        !Object.keys(existingSlots).length &&
        !params.has('date')
      ) {
        setCalendarDate(
          tempSlots[allDays[0]].length
            ? tempSlots[allDays[0]][0].start
            : DateTime.now().toISO()
        )
      }
    },
    []
  )

  const pullSlots = useCallback(
    async (month: string, loadingSlots = false) => {
      try {
        const formattedMonth = DateTime.fromFormat(month, 'yyyy-LL').toFormat(
          'LL/yyyy'
        )
        if (months.includes(formattedMonth)) {
          return
        }
        if (loadingSlots) {
          setSlotsLoading(true)
        } else {
          setCalendarLoading(true)
        }
        lastMonthRequested.current = month
        const response = await fetch(
          `${getBookingLinkInfoUrl()}?${[
            urlPrefix ? 'urlPrefix=' + urlPrefix : '',
            linkSlug ? 'linkSlug=' + linkSlug : '',
            bookingId ? 'bookingId=' + bookingId : '',
          ].join('&')}&month=${month}&durationChoice=${duration}`
        )
        const result = await response.json()
        if (lastMonthRequested.current === month) {
          groupSlots(result.availabilitySlots, groupedSlots, [formattedMonth])
        }
      } catch (e) {
        errorInDev(e)
        setLoadError(true)
        Sentry.captureException(e, { tags: { position: 'pullSlots' } })
      } finally {
        setSlotsLoading(false)
        setCalendarLoading(false)
      }
    },
    [groupSlots, groupedSlots, linkSlug, urlPrefix, bookingId, months, duration]
  )

  useEffect(
    function loadAvailabilities() {
      const getAvailabilitiesDetails = async () => {
        if (!linkSlug && !bookingId) {
          setLoadError(true)
          setLoading(false)
          return
        }
        try {
          const params = new URLSearchParams(window.location.search)
          let durationChoice
          if (params.has('d')) {
            durationChoice = parseInt(params.get('d')!)
          }
          const response = await fetch(
            `${getBookingLinkInfoUrl()}?${[
              urlPrefix ? 'urlPrefix=' + urlPrefix : '',
              linkSlug ? 'linkSlug=' + linkSlug : '',
              bookingId ? 'bookingId=' + bookingId : '',
              durationChoice ? 'durationChoice=' + durationChoice : '',
            ]
              .filter(Boolean)
              .join('&')}`
          )
          const result = await response.json()
          if (result.error) {
            throw result.error
          } else if (!result.id || !result.hostDisplayName) {
            throw new Error('incomplete data')
          } else {
            setLoadError(false)
            // prioritize invitee email from query params
            if (!userEmail) {
              setInviteeEmail(result.inviteeEmail || '')
            }
            setInviteeName(result.inviteeName || '')
            setInitialName(result.inviteeName || '')
            setAuthorName(result.hostDisplayName)
            setEventName(result.externalEventName || '')
            setFullLinkId(result.id)
            setTeamId(result.teamId)

            /**
             * Calculates whether to show the confirmation page for the booking
             * link */
            const isOnConfirmationPage =
              !bookingId && result.isSingleUse && result.inviteeBookings?.length

            if (result.durationChoices && result.durationChoices.length > 1) {
              setDurationChoices(result.durationChoices)
              setDuration(0)
              setCardPage(
                isOnConfirmationPage ? 'confirmation' : 'durationChoice'
              )
              if (result.durationAvailabilitySlots) {
                setDurationAvailabilitySlots(result.durationAvailabilitySlots)
              }
            } else if (
              result.durationChoices &&
              result.durationChoices.length === 1
            ) {
              setDuration(result.durationChoices[0])
              setCardPage(isOnConfirmationPage ? 'confirmation' : 'booking')
            } else {
              setDuration(result.duration || 0)
              setCardPage(isOnConfirmationPage ? 'confirmation' : 'booking')
            }
            if (result.questions) {
              setQuestions(
                result.questions.map((x: any) => ({ ...x, answer: '' }))
              )
            }

            if (result.linkRange) {
              setLinkRange(result.linkRange)
            }
            if (result.bookingData) {
              setBookingData(result.bookingData)

              // prioritize invitee email from query params
              if (!userEmail) {
                setInviteeEmail(result.bookingData.inviteeEmail)
              }
            }

            if (isOnConfirmationPage) {
              setConfirmedSlot({
                start: result.inviteeBookings[0].eventStart,
                end: result.inviteeBookings[0].eventEnd,
              })
            } else if (result.availabilitySlots) {
              // this branch will only get called for single duration links
              groupSlots(result.availabilitySlots, {})
            }
            setTzAbbr(getTzAbbr(DateTime.now().zoneName))
          }
        } catch (e) {
          errorInDev(e)
          setLoadError(true)
          if ((e as any)?.message === 'Failed to fetch') {
            return
          }

          Sentry.captureException(e, {
            tags: { position: 'getAvailabilitiesDetails' },
          })
        } finally {
          setLoading(false)
        }
      }

      getAvailabilitiesDetails()
    },
    [linkSlug, groupSlots, bookingId, urlPrefix, userEmail]
  )

  useEffect(() => {
    logEvent('AVAILABILITY_VIEW_LINK')
    const params = new URLSearchParams(window.location.search)
    if (params.has('date')) {
      logEvent('AVAILABILITY_VIEW_LINK_DATE')
    }
  }, [])

  const selectSlot = (slot: any) => {
    setSelectedSlot(slot)
    setModalVisible(true)
    logEvent('AVAILABILITY_SLOT_CLICKED')
  }

  const confirmSlot = useCallback(async () => {
    setSaving(true)

    let result
    try {
      if (linkSlug) {
        const newInviteeEmail = inviteeEmail.trim()
        const newInviteeName = inviteeName.trim()
        if (!newInviteeEmail) {
          setSavingError('No email entered')
          return
        } else if (!isEmailValid(newInviteeEmail)) {
          setSavingError('Invalid email entered')
          return
        } else if (!newInviteeName) {
          setSavingError('Please enter your name (for the event title)')
          return
        } else if (guestsError) {
          setSavingError(
            'Please ensure all email addresses are properly formatted.'
          )
          return
        }
        if (questions && questions.length) {
          const unansweredRequiredQuestions = questions.filter(
            (x: any) => x.required && !x.answer.trim().length
          )
          if (unansweredRequiredQuestions.length) {
            setSavingError('Please answer all required questions.')
            return
          }
        }

        const data = {
          start: selectedSlot.start,
          end: selectedSlot.end,
          recipientName: newInviteeName,
          recipientEmail: newInviteeEmail,
          recipientTimezone: DateTime.now().zoneName,
          recipientGuests: guestsList,
          urlPrefix,
          linkId: fullLinkId,
          linkShortId: linkSlug,
          bookingId,
          questions,
          durationChoice: duration,
        }
        result = await fetch(getCreateBookingUrl(), {
          method: 'post',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        setInviteeName(newInviteeName)
        setInviteeEmail(newInviteeEmail)
      } else if (bookingId) {
        result = await fetch(getRescheduleUrl(bookingId), {
          method: 'post',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            start: selectedSlot.start,
            end: selectedSlot.end,
            bookingId,
            reason,
            urlPrefix,
            linkShortId: linkSlug,
          }),
        })
      }

      if (result) {
        if (result.ok) {
          setConfirmedSlot(selectedSlot)
          setCardPage('confirmation')
        } else {
          const jsonResult = await result.json()
          throw new Error(jsonResult?.error?.message || jsonResult?.message)
        }
      }

      logEvent('AVAILABILITY_BOOKED', {
        inviteeEmail,
      })
      if (onBookingCreated) {
        onBookingCreated()
      }
    } catch (e: any) {
      if (e && e.message) {
        setSavingError(e.message)
      } else {
        setSavingError('Issue while trying to book this slot')
      }
      logEvent('AVAILABILITY_BOOKING_ERROR')
      Sentry.captureException(e, { tags: { position: 'confirmSlot' } })
    } finally {
      setSaving(false)
    }
  }, [
    inviteeName,
    inviteeEmail,
    linkSlug,
    fullLinkId,
    bookingId,
    selectedSlot,
    reason,
    guestsError,
    guestsList,
    questions,
    duration,
    urlPrefix,
    onBookingCreated,
  ])

  const selectionCalendarHandler = useClosure((date: DateTime) => {
    const dayString = date.toFormat('LL/dd/yyyy')
    const newDayIndex = days.findIndex((dayStr) => dayStr === dayString)
    if (newDayIndex !== -1) {
      setDayIndex(newDayIndex)
      setCalendarDate(days[newDayIndex])
      setCalendarDateClicked(true)
    }
  })

  const incrementCalendar = useCallback(
    (log = false) => {
      setCalendarDate((prev) => {
        const newDate = parseDate(prev).plus({ month: 1 }).startOf('month')

        void pullSlots(newDate.toFormat('yyyy-LL')).then(() =>
          nextFrame(() => selectionCalendarHandler(newDate))
        )

        return newDate.toISO()
      })
      if (log) {
        logEvent('AVAILABILITY_CALENDAR_ARROW_CLICK')
      }
    },
    [pullSlots, selectionCalendarHandler]
  )

  const decrementCalendar = useCallback(
    (log = false) => {
      setCalendarDate((prev) => {
        const newDate = parseDate(prev).minus({ month: 1 }).startOf('month')

        void pullSlots(newDate.toFormat('yyyy-LL')).then(() =>
          nextFrame(() => selectionCalendarHandler(newDate))
        )

        return newDate.toISO()
      })
      if (log) {
        logEvent('AVAILABILITY_CALENDAR_ARROW_CLICK')
      }
    },
    [pullSlots, selectionCalendarHandler]
  )

  const incrementDayIndex = useCallback(() => {
    const incrementBy = maxOneColumn ? 1 : maxTwoColumn ? 2 : 3

    setDayIndex((prev) => {
      const newIndex = Math.max(
        Math.min(prev + incrementBy, days.length - 1),
        0
      )
      const calendarCanIncrement =
        parseDate(days[prev]).plus({ month: 1 }).startOf('month') <
        parseDate(linkRange.end)

      const isgreaterThanDays = newIndex + incrementBy > days.length

      if (isgreaterThanDays && calendarCanIncrement) {
        // check if we need to load more events
        if (days[prev] && newIndex - prev === incrementBy) {
          // moved by 3 or 1 (a full panel/length), so staying on same month
          pullSlots(
            DateTime.fromFormat(days[prev], 'LL/dd/yyyy')
              .plus({ month: 1 })
              .toFormat('yyyy-LL'),
            true
          )
          setCalendarDate(days[newIndex])
        } else {
          incrementCalendar()
        }
      } else {
        setCalendarDate(days[newIndex])
      }
      return newIndex
    })
    logEvent('AVAILABILITY_DAYS_ARROW_CLICK')
  }, [
    maxOneColumn,
    maxTwoColumn,
    days,
    linkRange.end,
    pullSlots,
    incrementCalendar,
  ])

  const decrementDayIndex = useCallback(() => {
    const decrementBy = maxOneColumn ? 1 : maxTwoColumn ? 2 : 3

    setDayIndex((prev) => {
      const newIndex = Math.max(prev - decrementBy, 0)
      const calendarCanDecrement =
        parseDate(days[newIndex]).minus({ month: 1 }).endOf('month') >
        parseDate(linkRange.start)

      if (calendarCanDecrement) {
        pullSlots(parseDate(days[newIndex]).toFormat('yyyy-LL'), true)
        if (!newIndex || newIndex === -1) {
          decrementCalendar()
        } else {
          setCalendarDate(days[newIndex])
        }
      } else {
        setCalendarDate(days[newIndex])
      }
      return newIndex
    })
    logEvent('AVAILABILITY_DAYS_ARROW_CLICK')
  }, [
    maxOneColumn,
    maxTwoColumn,
    days,
    linkRange.start,
    pullSlots,
    decrementCalendar,
  ])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('date')) {
      const dayString = params.get('date')!.replace(/-/g, '/')
      const newDayIndex = days.findIndex((dayStr) => dayStr === dayString)
      if (newDayIndex !== -1) {
        setDayIndex(newDayIndex)
        setCalendarDate(days[newDayIndex])
        setCalendarDateClicked(true)
      }
    }
  }, [days])

  const guestsChangeHandler = (value: string[]) => {
    setGuestsLists(value)
    let hasError = false
    value.forEach((guest) => {
      if (!isEmailValid(guest)) {
        hasError = true
      }
    })
    setGuestsError(hasError)
  }

  const expandGuests = useCallback(() => {
    setGuestsExpanded(true)
    logEvent('AVAILABILITY_EXPAND_GUESTS_CLICK')
  }, [])

  const signature = (
    <a
      className='availabilities-signature'
      href={
        cardPage === 'confirmation'
          ? 'https://www.usemotion.com/referral?ref=booking_confirmation_removed_cta'
          : 'https://www.usemotion.com/referral?ref=booking'
      }
      target='_blank'
      onClick={() => logEvent('AVAILABILITY_SIGNATURE_CLICK')}
      rel='noopener noreferrer'
    >
      <img src={logo} className='availabilities-logo' alt='Motion logo' />
      Powered by{' '}
      {cardPage === 'confirmation' &&
      !window.navigator.userAgent.includes('Mobile') ? (
        <>
          <p
            style={{
              color: 'inherit',
              fontWeight: 'inherit',
              fontSize: 'inherit',
              marginLeft: 4,
            }}
          >
            Motion
          </p>
          : get 25% more done with A.I.
        </>
      ) : (
        'Motion'
      )}
    </a>
  )

  const renderBanner = () => {
    return (
      <div className='availabilities-banner'>
        <div className='availabilities-banner-stripe' />
        <div className='availabilities-banner-content'>
          {cardPage === 'confirmation' ? (
            <div className='availability-confirmation-banner-title'>
              {bookingId
                ? 'Meeting rescheduled successfully'
                : 'Meeting booked successfully'}
            </div>
          ) : (
            <>
              <div className='availability-pretitle'>
                {loading
                  ? 'Meeting'
                  : `${eventName || 'Meet'} with ${
                      teamId ? authorName : authorName.split(' ')[0]
                    }`}
              </div>
              {/* TODO: Add back in period after you. This was removed for QA */}
              {bookingId ? null : (
                <div className='availability-title'>
                  {bookingId
                    ? 'Pick a new time that works for you'
                    : `Thanks for taking the time to meet${
                        initialName ? `, ${initialName}!` : '!'
                      } Please pick a time that works for you`}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  const renderQuestion = (question: any, idx: number) => {
    return (
      <div key={idx} className='availabilities-question-row'>
        <div className='availabilities-input-label' style={{ marginTop: 16 }}>
          {question.text}
          {question.required ? (
            <span className='text-semantic-error-text-default'>*</span>
          ) : null}
        </div>
        {question.questionType === 'TEXT_MULTIPLE_LINES' ? (
          <TextField
            multiline
            onChange={(newText) => {
              setQuestions((prev) => {
                prev[idx].answer = newText
                return [...prev]
              })
            }}
            autoSize={{ minRows: 6, maxRows: 10 }}
          />
        ) : null}
        {question.questionType === 'TEXT_ONE_LINE' ? (
          <TextField
            value={question.answer}
            onChange={(newText) => {
              setQuestions((prev) => {
                prev[idx].answer = newText
                return [...prev]
              })
            }}
            autoFocus={false}
          />
        ) : null}
        {question.questionType === 'MULTIPLE_CHOICE' ? (
          <RadioButtonGroup
            options={question.choices}
            value={question.answer}
            onChange={(value) => {
              const answer = value
              setQuestions((prev) => {
                prev[idx].answer = answer
                return [...prev]
              })
            }}
          />
        ) : null}
      </div>
    )
  }

  const renderDurationChoiceCard = () => {
    return cardPage === 'durationChoice' ? (
      <div
        className='w-[500px] h-full p-6 flex flex-col items-center max-[500px]:w-full'
        style={{
          padding: 30,
          minHeight: 500,
        }}
      >
        <div
          className='availabilities-confirm-title'
          style={{ marginBottom: 24 }}
        >
          How long would you like to meet?
        </div>
        {durationChoices.map((choice) => (
          <div
            className='availabilities-duration-choice'
            key={choice}
            onClick={() => {
              setDuration(choice)
              groupSlots(durationAvailabilitySlots[choice], {})
              setCardPage('booking')
            }}
          >
            <span
              style={{
                color: '#151515',
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {formatDurationTime(choice)}
            </span>
            <ChevronRightOutline width={18} height={18} />
          </div>
        ))}
      </div>
    ) : null
  }

  const renderBookingCard = () => {
    const calendarCanIncrement =
      parseDate(calendarDate).plus({ month: 1 }).startOf('month') <
      parseDate(linkRange.end)

    const calendarCanDecrement =
      parseDate(calendarDate).minus({ month: 1 }).endOf('month') >
      parseDate(linkRange.start)

    const canDecrement = days.length > 0 && (!!dayIndex || calendarCanDecrement)
    const canIncrement =
      days.length > 0 && (dayIndex + 3 < days.length || calendarCanIncrement)

    return cardPage === 'booking' ? (
      <div
        data-theme='light'
        className={`availabilities-booking-inner${
          calendarDateClicked ? ' availabilities-calendar-date-clicked' : ''
        }`}
      >
        <div className='availabilities-date-column'>
          <div className='availabilities-datetimes-header mb-2'>
            {bookingData ? 'Original Event' : 'Select Date'}
          </div>
          {bookingData && bookingData.eventStart ? (
            <div className='mb-6'>
              <div className='availabilities-cancel-subtitle'>
                {formatFullDate(bookingData.eventStart)}
              </div>
              <div className='availabilities-cancel-subtitle'>
                {formatTimeRange(
                  bookingData.eventStart,
                  bookingData.eventEnd,
                  tzAbbr
                )}
              </div>
            </div>
          ) : null}
          {calendarLoading ? (
            <div className='flex items-center justify-center w-[252px] h-[274px] mx-[3.5]'>
              <LoadingSpinner />
            </div>
          ) : (
            <DatePicker
              mode='single'
              variant='highlight'
              value={parseDate(calendarDate).toISODate()}
              disabledDate={(value) => {
                const formatted = value.toFormat('LL/dd/yyyy')
                return !days.includes(formatted)
              }}
              onChange={(value) =>
                selectionCalendarHandler(
                  value == null
                    ? DateTime.now().startOf('day')
                    : Array.isArray(value)
                      ? parseDate(value[0])
                      : parseDate(value)
                )
              }
              onPreviousMonth={() => decrementCalendar(true)}
              onNextMonth={() => incrementCalendar(true)}
            />
          )}
          {bookingData ? null : (
            <div className='availabilities-timezone'>{tzAbbr}</div>
          )}
        </div>
        <div className='availabilities-time-selection'>
          <div className='availabilities-time-selection-mobile-header'>
            <div
              className='availabilities-back-button'
              onClick={() => setCalendarDateClicked(false)}
            >
              <ArrowLeftOutline
                style={{ marginRight: 8, fontSize: 16, width: 16, height: 16 }}
              />
            </div>
            <div className='availabilities-timezone'>{tzAbbr}</div>
          </div>
          <div className='availabilities-time-selection-header'>
            <div className='availabilities-time-selection-header-left gap-2'>
              <div
                className='availabilities-datetimes-header'
                style={{ whiteSpace: 'nowrap' }}
              >
                Select Time
              </div>
              {loading ? null : (
                <div className='availabilities-duration-title'>
                  <ClockSolid className='availabilities-clock-icon w-4 h-4' />
                  {formatDurationTime(duration)}
                </div>
              )}
              {durationChoices && durationChoices.length > 1 ? (
                <Button
                  variant='muted'
                  sentiment='neutral'
                  // @ts-expect-error - old style
                  className='text-[#1890ff]'
                  onClick={() => {
                    setCardPage('durationChoice')
                    setCalendarDateClicked(false)
                  }}
                >
                  Change
                </Button>
              ) : null}
            </div>
            <div>
              <div style={{ display: 'flex', userSelect: 'none' }}>
                <IconButton
                  disabled={!canDecrement}
                  Icon={ChevronLeftOutline}
                  onClick={canDecrement ? decrementDayIndex : undefined}
                  style={{ marginRight: 8 }}
                  className={`availability-day-arrow${
                    canDecrement ? ' availability-day-arrow-active' : ''
                  }`}
                />
                <IconButton
                  disabled={!canIncrement}
                  Icon={ChevronRightOutline}
                  onClick={canIncrement ? incrementDayIndex : undefined}
                  className={`availability-day-arrow${
                    canIncrement ? ' availability-day-arrow-active' : ''
                  }`}
                />
              </div>
            </div>
          </div>
          {loading || slotsLoading ? (
            <div className='availabilities-spinner-container'>
              <LoadingSpinner size={22} color='#979797' />
            </div>
          ) : (
            <div className='availabilities-day-columns-container'>
              {days.slice(dayIndex, dayIndex + 3).map((dayString, offset) => (
                <div
                  className={`availabilities-day-region ${
                    offset === 2 ? 'third' : offset === 1 ? 'second' : 'first'
                  }`}
                  key={dayString}
                >
                  <div
                    className='availabilities-day-header'
                    style={{ marginBottom: 16 }}
                  >
                    <div className='availabilities-subsection-title'>
                      {parseDate(dayString).toFormat('ccc LLL d')}
                    </div>
                  </div>
                  {groupedSlots[dayString] &&
                    groupedSlots[dayString].map((slot: any) => (
                      <div
                        key={`${dayString}${slot.start}`}
                        className='availabilities-item'
                        onClick={() => selectSlot(slot)}
                      >
                        {parseDate(slot.start).toFormat('h:mma')}
                        {slot.preferred ? (
                          <Tooltip content='Preferred slots work better, but all listed slots are available.'>
                            <div className='availabilities-preferred-badge'>
                              Preferred
                            </div>
                          </Tooltip>
                        ) : (
                          <div />
                        )}
                      </div>
                    ))}
                </div>
              ))}
              {days.length ? null : (
                <div className='availabilities-empty-placeholder'>{`No availabilities for ${parseDate(
                  calendarDate
                ).toFormat(
                  'LLLL yyyy'
                )}. Click arrows to check other months.`}</div>
              )}
            </div>
          )}
        </div>
        <Modal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          saving={saving}
        >
          <div className='availabilities-modal-content' data-theme='light'>
            <div className='availabilities-form'>
              <IconButton
                Icon={XOutline}
                className='availabilities-close-button'
                onClick={() => setModalVisible(false)}
              />
              <div
                className='availabilities-datetimes-header'
                style={{ fontWeight: 600 }}
              >
                {`${eventName || 'Meet'} with ${authorName.split(' ')[0]}`}
              </div>
              <div className='availabilities-modal-subtitle'>
                {selectedSlot.start && selectedSlot.end
                  ? formatFullDateTimeRange(
                      selectedSlot.start,
                      selectedSlot.end,
                      tzAbbr
                    )
                  : ''}
              </div>
              {bookingId ? (
                <>
                  <div className='availabilities-input-label'>
                    Reason for rescheduling
                  </div>
                  <TextField
                    value={reason}
                    multiline
                    autoSize={{ minRows: 6, maxRows: 10 }}
                    onChange={(value) => setReason(value)}
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <div className='availabilities-input-label'>Name</div>
                  <TextField
                    placeholder='Name'
                    value={inviteeName}
                    onChange={(value) => setInviteeName(value)}
                    autoFocus={!inviteeName}
                    fullWidth
                  />
                  <div
                    className='availabilities-input-label'
                    style={{ marginTop: 16 }}
                  >
                    Email
                  </div>
                  <TextField
                    type='email'
                    placeholder='Email'
                    value={inviteeEmail}
                    onChange={(value) => setInviteeEmail(value)}
                    autoFocus={!!inviteeName && !inviteeEmail}
                  />
                  {guestsExpanded ? (
                    <>
                      <div
                        className='availabilities-input-label'
                        style={{ marginTop: 16 }}
                      >
                        Guest Email(s)
                      </div>
                      <EmailSelect
                        items={guestsList}
                        onChange={guestsChangeHandler}
                      />
                      {guestsError ? (
                        <div className='availabilities-error'>
                          Please ensure all email addresses are properly
                          formatted.
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      className='availabilities-add-guests-button'
                      onClick={expandGuests}
                    >
                      +Add guests
                    </div>
                  )}
                  {questions && questions.length
                    ? questions.map((question, idx) =>
                        renderQuestion(question, idx)
                      )
                    : null}
                </>
              )}
              {savingError ? (
                <div className='availabilities-error'>{savingError}</div>
              ) : null}
            </div>
            <Button
              onClick={() => {
                if (!saving) {
                  void confirmSlot()
                }
              }}
              // @ts-expect-error - old style
              className='availabilities-confirm-button'
              disabled={saving}
            >
              {bookingId ? 'Reschedule Event' : 'Schedule Event'}

              {saving ? (
                <LoadingSpinner className='ml-3' size={22} color='#979797' />
              ) : null}
            </Button>
          </div>
        </Modal>
      </div>
    ) : null
  }

  const renderConfirmation = () => {
    return cardPage === 'confirmation' ? (
      <div className='availabilities-confirm-inner-container'>
        <div className='availabilities-confirm-check-container'>
          <CheckOutline
            className='availabilities-confirm-check'
            style={{
              backgroundColor: '#2C77E7',
              color: 'white',
              borderRadius: '50%',
              width: 'auto',
              padding: '8px',
            }}
          />
        </div>
        <div className='availabilities-confirm-title'>{`${
          eventName || 'Meet'
        } with ${authorName.split(' ')[0]}`}</div>
        <div className='availabilities-confirm-subtitle'>
          {confirmedSlot.start && confirmedSlot.end
            ? formatFullDateTimeRange(
                confirmedSlot.start,
                confirmedSlot.end,
                tzAbbr
              )
            : ''}
        </div>
        <div className='availabilities-confirm-message'>
          {bookingId
            ? 'The event invite has been updated.'
            : `An invite has been sent to ${inviteeEmail}.`}
        </div>
        {/* <Divider style={{ color: "#d7d7d7" }}/>*/}
        {/* <div className={"availabilities-cta-text"}>Maximize focus time and deep work with Motion.</div>*/}
        {/* <Button type={"primary"} className={"availabilities-confirm-button-blue"} onClick={() => {*/}
        {/*  logEvent("AVAILABILITY_CONFIRMATION_CTA_CLICK");*/}
        {/*  window.open("https://www.usemotion.com/referral?ref=booking_confirmation_cta", "_blank");*/}
        {/* }}>Try it</Button>*/}
      </div>
    ) : null
  }

  const renderCard = () => {
    return (
      <div className='availabilities-card-container'>
        <div
          className={`availabilities-card${
            cardPage === 'confirmation' || cardPage === 'durationChoice'
              ? ' availabilities-card-confirmation'
              : ''
          }`}
        >
          {renderDurationChoiceCard()}
          {renderBookingCard()}
          {renderConfirmation()}
        </div>
        {signature}
      </div>
    )
  }

  if (loadError) {
    return <BookingLinkExpired />
  }

  return (
    <div
      data-theme='light'
      className={twMerge(
        'availabilities-container',
        isFullPage ? 'fill-page' : 'w-full h-full'
      )}
    >
      {renderBanner()}
      {renderCard()}
    </div>
  )
}

type ModalProps = {
  visible: boolean
  saving: boolean
  onClose(): void
  children: ReactNode
}
function Modal(props: ModalProps) {
  const { visible, saving, onClose, children } = props
  const isMobile = isMobileExperience()

  useEffect(() => {
    if (!visible) return
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [visible])

  if (!visible) return null

  if (isMobile) {
    return <div className='booking-custom-modal'>{children}</div>
  }
  return (
    <BaseModal
      expanded
      // @ts-expect-error - hidden property
      className='relative sm:max-w-max max-sm:max-w-[initial] max-sm:max-h-[initial] sm:h-min'
      onClose={() => {
        if (!saving) {
          onClose()
        }
      }}
      visible={visible}
    >
      {props.children}
    </BaseModal>
  )
}

type RadioButtonGroupProps = {
  options: string[]
  value: string
  onChange(value: string): void
}
const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  return (
    <div className='mx-1'>
      {props.options.map((opt, idx) => (
        <RadioButton
          key={idx}
          checked={props.value === opt}
          onChange={(value) => props.onChange(value)}
          option={opt}
        />
      ))}
    </div>
  )
}

interface RadioButtonProps {
  checked: boolean
  option: string
  onChange: (value: string) => void
}
const RadioButton = ({ checked, option, onChange }: RadioButtonProps) => (
  <label className='flex items-center gap-x-2.5 cursor-pointer text-sm'>
    <input
      type='radio'
      checked={checked}
      onChange={(value) => onChange(value.target.value)}
      value={option}
    />
    {option}
  </label>
)

function nextFrame(fn: () => void) {
  setTimeout(fn, 0)
}
