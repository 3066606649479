import './polyfill'
import '@motion/theme/vars.css'
import '@motion/web-common/firebase'

import { SharedStateProvider } from '@motion/react-core/shared-state'
import { getSegmentAnalytics } from '@motion/web-base/analytics'
import { initializeSentry } from '@motion/web-base/sentry'
import { FirebaseAuthSync } from '@motion/web-common/auth'
import { featureFlags } from '@motion/web-common/flags'
import { ModalApiProvider } from '@motion/web-common/modals'
import { ConnectedRpcProvider } from '@motion/web-common/rpc'

import { ClickToComponent } from 'click-to-react-component'
import { HandleLoadErrors } from 'health'
import { Settings } from 'luxon'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { useLocation } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import { initializeAmplitude } from './analytics'
import { App } from './App'
import { EnsureOnline } from './areas/auth/components/ensure-online'
import { InitializeAnalytics } from './areas/auth/initialize-analytics'
import { DevelopmentModeBanner } from './development-mode-banner'
import * as serviceWorker from './serviceWorker'

Settings.defaultLocale = 'en-US'

initializeSentry({
  version: __SENTRY_RELEASE__,
  allowUrls: [import.meta.env.MOTION_FRONTEND_HOST],
  onBeforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'ui.click' && hint != null) {
      const { target } = hint.event
      const finalTarget = target.nearestViewportElement ?? target

      if (finalTarget.nodeName === 'svg') {
        breadcrumb.message = `svg > ${finalTarget.dataset.icon}`
      } else {
        const testIdValue = finalTarget.getAttribute('data-testid')
        breadcrumb.message = testIdValue
          ? `testid=${testIdValue}`
          : (finalTarget.ariaLabel ??
            finalTarget.innerText ??
            breadcrumb.message)
      }
    }
    return breadcrumb
  },
})
initializeAmplitude({ version: __SENTRY_RELEASE__ })

// @ts-expect-error - patching __SHA__ onto window
window.__MOTION_VERSION__ = __SENTRY_RELEASE__

/* eslint react-refresh/only-export-components: ["warn"] */
function LogPage() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== '/' && !pathname.startsWith('/web')) {
      getSegmentAnalytics()?.page()
    }
  }, [pathname])

  return null
}

featureFlags.enableTracking('stripe-trial-length', 'onboarding-iframes')

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(
  <EnsureOnline>
    {import.meta.env.MOTION_ENV === 'localhost' && (
      <ClickToComponent
        editor={import.meta.env.MOTION_CLICK_TO_COMPONENT_EDITOR ?? 'vscode'}
      />
    )}
    <HandleLoadErrors>
      <SharedStateProvider name='root' batchTime={0}>
        <ConnectedRpcProvider>
          <ModalApiProvider>
            <Router>
              <FirebaseAuthSync />
              <LogPage />
              <InitializeAnalytics />
              {import.meta.env.MOTION_ENV === 'localhost' && (
                <DevelopmentModeBanner />
              )}
              <App />
            </Router>
          </ModalApiProvider>
        </ConnectedRpcProvider>
      </SharedStateProvider>
    </HandleLoadErrors>
  </EnsureOnline>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
