import { LoadingSpinner } from '@motion/ui/base'
import { logInDev, makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { useEffect, useRef } from 'react'

const log = makeLog('loading-page')

type LoadingPageProps = {
  id: string
  debugMessage?: string
}
export const LoadingPage = (props: LoadingPageProps) => {
  const startTimeRef = useRef(performance.now())
  useEffect(() => {
    const startTime = startTimeRef.current
    return () => {
      const unmountTime = performance.now()
      const totalTime = unmountTime - startTime
      logInDev(
        `Time showing preparing motion "${props.id}": ${totalTime.toFixed(2)}ms`
      )
    }
  }, [props.id])

  useEffect(() => {
    const handle = setTimeout(() => {
      Sentry.captureException(new Error(`Long loading screen`), {
        extra: {
          id: props.id,
          debugMessage: props.debugMessage,
        },
      })
    }, 20_000)
    return () => clearTimeout(handle)
  }, [props.debugMessage, props.id])

  log('visible', props.id)

  return (
    <>
      <div className='absolute left-0 top-0 w-full h-8 electron-drag' />
      <div className='static-loading relative'>
        <div className='flex items-center relative gap-4 max-w-[600px]'>
          <LoadingSpinner size={32} />
          <h2>Preparing Motion...</h2>
        </div>
      </div>
    </>
  )
}
