import { Button } from '@motion/ui/base'

type SomethingBrokeProps = {
  error: Error
}

export const SomethingBroke = ({ error }: SomethingBrokeProps) => {
  return (
    <div id='initial-loading' className='static-loading relative'>
      <div className='flex flex-col items-center relative gap-4 max-w-[600px]'>
        <div className='absolute left-0 top-0 w-full h-8 electron-drag'></div>
        <h2>
          <span>Oops! Something went wrong.</span>
        </h2>
        <h3 className='flex flex-col gap-2 items-center'>
          <p>Motion was unable to load. Try refreshing your browser</p>
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </h3>
        {import.meta.env.MODE === 'production' ? null : (
          <DisplayDevError error={error} />
        )}
      </div>
    </div>
  )
}

type DisplayDevErrorProps = {
  error: unknown
}

const DisplayDevError = (props: DisplayDevErrorProps) => {
  const text =
    props.error instanceof Error
      ? formatStack(props.error)
      : 'Unknown: ' + JSON.stringify(props.error, undefined, 2)

  return (
    <div className='flex flex-col gap-3'>
      <div
        className='text-light-1100 dark:text-dark-400 first:pl-0'
        style={{ whiteSpace: 'unset', textAlign: 'left' }}
      >
        {text}
      </div>
    </div>
  )
}

function formatStack(ex: Error) {
  let cur = ex
  const elements = []
  let depth = 0
  while (cur != null) {
    elements.push(formatError(cur, depth))
    if (!(ex.cause instanceof Error)) break
    cur = ex.cause
    depth++
  }
  return elements
}

function formatError(ex: Error, depth: number) {
  const message = ex.toString()
  const stackLines = (ex.stack ?? '')?.split('\n')

  const lines = [message, ...stackLines.slice(1, 4).map(formatLine)]

  return (
    <div className='first:pl-0' key={depth}>
      {lines.map((x, i) => (
        <pre key={i} className='pl-2'>
          {x}
        </pre>
      ))}
    </div>
  )
}

const LINE_RE = /http.+?((?:\/[^\/]+){3}):(\d+:\d+)/gm
function formatLine(line: string) {
  return line.replace(LINE_RE, (_, file) => `${file}`)
}
