import {
  EmailPasswordSignin,
  LoginButton,
  type LoginErrors,
  LoginScreen,
} from '@motion/ui/login'
import { isPasswordValid } from '@motion/ui-logic'
import { isEmailValid } from '@motion/utils/string'
import { markExposure, useHasTreatment } from '@motion/web-common/flags'

import { useOnboardingIframeUrl } from 'experiments'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { logEvent } from '../../../analytics'
import { signInApple, signInGoogle, signInMicrosoft } from '../../../utils'
import { EmailPasswordSignup } from '../components/email-password-signup'
import { signInWithFirebaseEmailPassword } from '../firebase-auth'
import { LoginEmailNotFoundModal } from '../modals/login-email-not-found-modal'
import { useCheckPendingInvite } from '../rpc'

type LoginPageProps = {
  title?: string
  showIframe: boolean
}

export const LoginPage = ({ title, showIframe }: LoginPageProps) => {
  const auth = getAuth(getApp())

  const params = new URLSearchParams(window.location.search)
  const emailCode = params.get('emailCode')
  const email = emailCode && window.atob(emailCode)

  const iframeUrl = useOnboardingIframeUrl('login')
  const signInRedirectEnabled = useHasTreatment('signin-redirect')
  const [loginNoEmailFound, setLoginNoEmailFound] = useState<string | null>(
    null
  )
  const { mutateAsync: checkInvited, isLoading } = useCheckPendingInvite()

  const [emailInput, setEmailInput] = useState<string>(email ?? '')
  const [password, setPassword] = useState('')
  const [emailLoginError, setEmailLoginError] = useState<null | LoginErrors>(
    null
  )
  const [isSigningUp, setIsSigningUp] = useState<boolean>(false)

  useEffect(() => {
    if (showIframe) {
      markExposure('onboarding-iframes')
    }
    markExposure('signin-redirect')
  }, [showIframe])

  const emailNotFound = async (providedEmail: string) => {
    const hasPendingInvite = await checkInvited({ email: providedEmail })

    if (hasPendingInvite.pendingInvite) {
      setIsSigningUp(true)
      return
    }

    setLoginNoEmailFound(providedEmail)
  }

  const signInWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    logEvent('DASHBOARD_MOTION_SIGNIN_CLICK', { email })
    setEmailLoginError(null)
    const result = await signInWithFirebaseEmailPassword(
      auth,
      emailInput,
      password
    )
    if (result.state === 'ERROR') {
      const firebaseError = result.error

      switch (firebaseError) {
        case 'auth/invalid-email':
          setEmailLoginError('auth/invalid-email')
        case 'auth/user-disabled':
          setEmailLoginError('auth/user-disabled')
          break
        case 'auth/user-not-found':
          await emailNotFound(emailInput)
          break
        case 'auth/wrong-password':
          try {
            const resp = await fetch(
              `${__BACKEND_HOST__}/users/login-provider?email=${emailInput}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            if (!resp.ok) {
              setEmailLoginError('auth/wrong-password-or-wrong-provider')
              return
            }
            const data = await resp.json()
            if (!data.provider || data.provider === 'password') {
              setEmailLoginError('auth/wrong-password')
            } else {
              switch (data.provider) {
                case 'google.com':
                  setEmailLoginError('auth/wrong-provider-google')
                  break
                case 'microsoft.com':
                  setEmailLoginError('auth/wrong-provider-microsoft')
                  break
                case 'apple.com':
                  setEmailLoginError('auth/wrong-provider-apple')
                  break
                default:
                  setEmailLoginError('auth/wrong-provider-unknown')
                  break
              }
              return
            }
          } catch (error) {
            setEmailLoginError('auth/wrong-password-or-wrong-provider')
            return
          }
          break
        default: {
          setEmailLoginError('unknown')
          // Logged in firebase-login utils
        }
      }
    }
  }

  if (isSigningUp && emailInput) {
    return (
      <div
        className='flex h-full w-full items-center justify-center bg-semantic-neutral-bg-subtle px-6 py-8'
        data-theme='light'
      >
        <EmailPasswordSignup
          email={emailInput}
          initialPassword={password}
          onBack={() => {
            setIsSigningUp(false)
          }}
        />
      </div>
    )
  }

  return (
    <LoginScreen
      iframeUrl={showIframe ? iframeUrl : ''}
      title={title ?? (email ? `👋 Log in with ${email}` : '👋 Welcome back!')}
      subtitle='Log in with your Google, Microsoft, or Apple account.'
    >
      <>
        <LoginButton
          onClick={() => signInGoogle(auth, signInRedirectEnabled)}
          provider='GOOGLE'
        />
        <LoginButton
          onClick={() => signInMicrosoft(auth, signInRedirectEnabled)}
          provider='MICROSOFT'
        />
        <LoginButton
          onClick={() => signInApple(auth, signInRedirectEnabled)}
          provider='APPLE'
        />
        <EmailPasswordSignin
          email={emailInput}
          loading={isLoading}
          password={password}
          setEmail={setEmailInput}
          setPassword={setPassword}
          disabled={!isEmailValid(emailInput) || !isPasswordValid(password)}
          onSubmit={signInWithEmail}
          error={emailLoginError}
        />

        {!!loginNoEmailFound && (
          <LoginEmailNotFoundModal
            email={loginNoEmailFound}
            onSignUp={() => setIsSigningUp(true)}
            onDismiss={() => setLoginNoEmailFound(null)}
          />
        )}
      </>
    </LoginScreen>
  )
}
