import {
  type MetricMetadata,
  onlineChanges,
  stats,
  visibilityChanges,
} from '@motion/web-common/performance'

import { useEffect } from 'react'

const DOMAIN = window.location.hostname.split('.').slice(-3).join('.')

export const RecordFetchTimes = () => {
  if (!__IS_BUNDLED__) return null
  return <RecordFetchTimeInternal />
}

declare global {
  interface PerformanceResourceTiming {
    renderBlockingStatus?: string
  }

  interface PerformanceNavigationTiming {
    renderBlockingStatus?: string
  }
}

const RecordFetchTimeInternal = () => {
  useEffect(() => {
    const observer = new PerformanceObserver((entries) => {
      const entry = entries.getEntriesByType('navigation')[0] as
        | PerformanceNavigationTiming
        | undefined
      if (entry == null) return

      stats.span({
        name: 'resource.fetch',
        start: entry.requestStart,
        end: entry.responseEnd,
        tags: [
          `resource_name:/index.html`,
          `domain:${DOMAIN}`,
          entry.renderBlockingStatus &&
            `blocking_render:${entry.renderBlockingStatus === 'blocking'}`,
        ].filter(Boolean),
      })

      observer.disconnect()
    })

    observer.observe({ buffered: true, type: 'navigation' })
  }, [])

  useEffect(() => {
    const observer = new PerformanceObserver((entries) => {
      const resources = entries.getEntriesByType(
        'resource'
      ) as PerformanceResourceTiming[]

      resources.forEach((entry) => {
        if (entry.initiatorType === 'fetch') return
        const localName = parseResourceName(entry.name)
        if (localName == null) return

        if (!isValidResource(localName)) return

        const duration = entry.responseEnd - entry.requestStart
        const shouldLog =
          entry.responseEnd < 0 ||
          entry.requestStart < 0 ||
          duration < 0 ||
          duration > 5_000

        const meta: MetricMetadata | undefined = shouldLog
          ? {
              log: true,
              metadata: {
                timing: entry,
                visibility: {
                  effective:
                    visibilityChanges.ifNoChanges(
                      entry.requestStart,
                      entry.responseEnd
                    ) ?? 'changed',
                  history: visibilityChanges.all,
                },
                online: {
                  effective:
                    onlineChanges.ifNoChanges(
                      entry.requestStart,
                      entry.responseEnd
                    ) ?? 'changed',
                  history: onlineChanges.all,
                },
              },
            }
          : undefined

        const deliveryType =
          'deliveryType' in entry
            ? (entry.deliveryType ?? 'network')
            : 'unknown'

        stats.span(
          {
            name: 'resource.fetch',
            start: entry.requestStart,
            end: entry.responseEnd,
            tags: [
              `resource_name:${localName}`,
              `domain:${DOMAIN}`,
              entry.renderBlockingStatus &&
                `blocking_render:${entry.renderBlockingStatus === 'blocking'}`,
              `delivery_type:${deliveryType}`,
            ].filter(Boolean),
          },
          meta
        )
      })
    })

    observer.observe({ buffered: true, type: 'resource' })
    return () => observer.disconnect()
  }, [])

  return null
}

const EXTRACT_FILENAME_RE = /^(\/.+?)(?:_[0-9a-f]+)?\.(.+?)(?:$|#)/i
function parseResourceName(fullName: string) {
  try {
    if (!URL.canParse(fullName)) return null

    const uri = new URL(fullName)
    if (!uri.hostname.endsWith(DOMAIN)) return null

    const pathname = __ASSET_PREFIX__
      ? uri.pathname.replace(`/${__ASSET_PREFIX__}`, '')
      : uri.pathname

    const match = pathname.match(EXTRACT_FILENAME_RE)
    if (match == null) return null
    return `${match[1]}.${match[2]}`
  } catch (e) {
    return null
  }
}

const VALID_PREFIX = ['/chunks', '/entry', '/styles', '/images', '/index.html']
function isValidResource(name: string) {
  return VALID_PREFIX.some((prefix) => name.startsWith(prefix))
}
