import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'
import { stats } from '@motion/web-common/performance'

import { LoadingPage } from '~/areas/global'
import { type ReactNode, useEffect, useState } from 'react'

import { SomethingBroke } from './something-broke'

const SESSION_KEY = 'motion:preload-failed-count'
const PRELOAD_ERROR_EVENT = 'vite:preloadError'

type HandleLoadErrorsProps = {
  children: ReactNode
}

type State = {
  count: number
  error: Error
}

export const HandleLoadErrors = (props: HandleLoadErrorsProps) => {
  const [preloadError, setPreloadError] = useState<null | State>(null)

  useEffect(() => {
    async function preloadListener(event: VitePreloadErrorEvent) {
      const count = safeParseInt(window.sessionStorage.getItem(SESSION_KEY), 0)
      errorInDev('Failed to load module', { event, count })

      const ex = event.payload
      Sentry.captureException(
        new Error('Failed to load module', { cause: ex }),
        {
          tags: { position: 'load' },
          extra: {
            count,
          },
        }
      )

      stats.increment('module_load_failure')

      setPreloadError({
        count,
        error: ex,
      })

      if (count < 3) {
        window.sessionStorage.setItem(SESSION_KEY, String(count + 1))
        await Sentry.flush()
        return setTimeout(
          () => {
            window.location.reload()
          },
          1_000 * (count + 1)
        )
      }

      window.sessionStorage.removeItem(SESSION_KEY)
    }

    // reload the page if we fail to load a dynamic import
    // https://vitejs.dev/guide/build#load-error-handling
    window.addEventListener(PRELOAD_ERROR_EVENT, preloadListener)

    return () =>
      window.removeEventListener(PRELOAD_ERROR_EVENT, preloadListener)
  }, [])

  if (preloadError) {
    if (preloadError.count >= 3) {
      return <SomethingBroke error={preloadError.error} />
    }
    return <LoadingPage id='preload-error' />
  }

  return props.children
}

function safeParseInt(value: string | null, defaultValue: number): number {
  if (!value) return defaultValue
  const parsed = parseInt(value)
  if (isNaN(parsed)) return defaultValue
  return parsed
}

if (import.meta.env.MODE === 'localhost') {
  // @ts-expect-error - testing function
  window.simulateLoadError = function (count = 0) {
    if (count) {
      window.sessionStorage.setItem(SESSION_KEY, String(count))
    }
    const event = new Event(PRELOAD_ERROR_EVENT) as VitePreloadErrorEvent
    event.payload = new Error('Failed to load')

    window.dispatchEvent(event)
  }
}
