import { LoadingSpinner } from '@motion/ui/base'
import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import logo from '~/images/logo_1024.png'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'

import { logEvent } from '../../../analytics'

type Props = {
  providerName: string
}

const AUTH_ERROR_STRINGS = {
  InsufficientScope:
    '⚠️ Your account was not added because Motion does not have permission to access your calendar. Please reconnect and make sure the calendar permission is checked.',
  MultipleProvidersSameEmail:
    '⚠️ Motion doesn’t support adding email accounts with the same email. Some other options are 1. picking one of the two email accounts to add to Motion and removing the other from Motion, or 2. if one of your accounts is an iCloud account, use the @icloud.com version of your Apple ID so it doesn’t conflict with your other email account.',
  NoExchangeSupport:
    '⚠️ Your account was not added because Motion does not currently support adding Microsoft Exchange calendars.',
  GoogleAccessDenied:
    '⚠️ Motion cannot access the calendar at this Google email account. This usually means that some setting in your Google Workspace is blocking this access. Please follow the instructions at: https://app.usemotion.com/oauth-instructions',
  MicrosoftAccessDenied:
    '⚠️ Motion cannot access the calendar at this Microsoft email account. This usually means that some setting in your Microsoft tenant is blocking this access. Please contact your IT admin to grant these permissions.',
  MissingOAuthCode:
    '⚠️ There was an issue connecting your email account. Please close this window and try again. Also, please check that you are signed in using the correct email account. You can see your current account at: https://app.usemotion.com/account',
} as const

export const OauthResponse = (props: Props) => {
  const { providerName } = props

  const [processed, setProcessed] = useState(false)
  const [error, setError] = useState('')
  const auth = getAuth(getApp())

  useEffect(
    function verifyCode() {
      const process = async () => {
        const newParams = new URLSearchParams(window.location.search)
        const errorCode = newParams.get('error') ?? ''
        const errorDescription = newParams.get('error_description') ?? ''

        try {
          if (errorCode === 'access_denied') {
            setError(
              `To connect your ${providerName} account to Motion, you'll need to have your ` +
                'organization admin grant permissions for third-party applications to access ' +
                'your account. If the issue persists, please contact support at https://help.usemotion.com'
            )
            return
          } else if (errorCode) {
            const code = errorCode as
              | keyof typeof AUTH_ERROR_STRINGS
              | undefined
            const recogizedErrorMessage = code && AUTH_ERROR_STRINGS[code]

            if (recogizedErrorMessage) {
              setError(recogizedErrorMessage)
              logEvent('OAUTH_VERIFICATION_RECOGNIZED_ERROR', { errorCode })
              return
            }
            setError(
              `Auth failed. Please try again, or contact support at https://help.usemotion.com: ${errorDescription}`
            )
            return
          }
          window.close()
          setProcessed(true)
        } catch (e) {
          errorInDev(e)
          setError(
            `Error connecting your  ${providerName} account. Please contact support at https://help.usemotion.com`
          )
          Sentry.captureException(e, {
            tags: { position: 'verifyCode' },
          })
        }
      }

      if (!auth.currentUser) return
      void process()
    },
    [auth.currentUser, providerName]
  )

  return (
    <div className='fill ms-oauth-container'>
      <img src={logo} className='ob-logo' alt='Motion logo' />
      {error ? <p style={{ fontSize: 18, maxWidth: '70vw' }}>{error}</p> : null}
      {processed ? (
        <>
          <p
            style={{
              fontFamily: 'Heebo',
              fontWeight: 900,
              fontSize: 38,
              marginBottom: '0.5em',
            }}
          >
            Success!
          </p>
          <p style={{ fontSize: 18 }}>
            You&apos;ve connected your {providerName} account to Motion. You may
            close this window.
          </p>
        </>
      ) : null}
      {!processed && !error ? <LoadingSpinner color='#979797' /> : null}
    </div>
  )
}
