import { errorInDev } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import { type Auth } from 'firebase/auth'

export interface SubscriptionData {
  status: string
  endDate: string
  savingsAmount: string | null
  savingsPercent: string | null
  type: string
  customer: any | null
  subscription: any | null
  savings?: {
    savingsPct: number
    stripeAmount: number
  }
}

export const serverSideStripeSubscriptionCheck = async (
  auth: Auth
): Promise<SubscriptionData | null> => {
  try {
    return await request('subscriptions', 'POST', auth)
  } catch (e) {
    errorInDev(`Could not get stripe subscription: ${e}`)
    Sentry.captureException(e, {
      tags: { position: 'checkStripeSubscription' },
    })
    return null
  }
}

const makeRequest = async (
  auth: Auth,
  host: string,
  path: string,
  method: string,
  body?: Record<string, any>
) => {
  const user = auth.currentUser
  if (!user) {
    throw new Error('No current user')
  }

  const authToken = await user.getIdToken().catch((ex) => {
    Sentry.captureException(ex, {
      tags: { position: 'checkStripeSubscription' },
    })

    throw new Error('Unable to fetch the user auth token', { cause: ex })
  })

  return await fetch(`${host}/${path}`, {
    method,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  })
}

const request = async (
  path: string,
  method: string,
  auth: Auth,
  body?: Record<string, any>
) => {
  const response = await makeRequest(auth, getBackendHost(), path, method, body)
  if (!response.ok) {
    throw new Error('failed to fetch subscription', {
      cause: new Error(`[${response.status}] ${method} ${path}`),
    })
  }
  return await response.json()
}

const getBackendHost = () => {
  return import.meta.env.MOTION_BACKEND_HOST
}
